import { deviceConstants, userConstants, customerConstants } from '@constants/admin/';

export var DEVICE_INITIAL_STATE = {
  id:"",
  loading: true,
  error:"",

  deviceName: "",
  deviceType: "",
  appVersion: "",
  osId: "",
  osVersion: "",
  deviceModelNumber: "",
  lastLoginTime: "",
  currentStatus: "",
  pnsToken: "",
};

function CreateDeivce(state, id){
  if(state[id] === undefined)
    state[id] = Object.assign({}, DEVICE_INITIAL_STATE);
  state[id].id = id;
  state[id].loading = true;
  state[id].error = '';
  return state;
}

function PopupateDeivceDetails(state, item){
  state[item.id] = Object.assign({}, state[item.id], item, {loading: false});
  return state;
}

function setDeviceStatus(state, item){
  if(state[item.id] === undefined){
    state[item.id] = Object.assign({}, DEVICE_INITIAL_STATE);
    state[item.id].id = item.id;
    state[item.id].loading = false;
  }
  state[item.id].currentStatus = item.currentStatus;
  return state;
}

function setErrorDeivce(state, id, error){
  if(state[id] === undefined){
    state[id] = Object.assign({}, DEVICE_INITIAL_STATE);
    state[id].id = id;
  }
  state[id].loading = false;
  state[id].error = error;
  return state;
}

function UpdateUserDevice(state, items){
  items.forEach(function(device){
    if(state[device.id] === undefined){
      state[device.id] = Object.assign({}, DEVICE_INITIAL_STATE);
      state[device.id].id = device.id;
    }

    state[device.id] = Object.assign({}, state[device.id], device, {loading: false});
  })
  return state;
}

export function device(state = {}, action) {
  switch (action.type) {
    case deviceConstants.GET_DEVICE_REQUEST:
      return Object.assign({}, CreateDeivce(state, action.id));
    case deviceConstants.GET_DEVICE_SUCCESS:
      return Object.assign({}, PopupateDeivceDetails(state, action.payload));
    case deviceConstants.GET_DEVICE_FAILURE:
      return Object.assign({}, setErrorDeivce(state, action.id, action.error));
    case deviceConstants.SET_DEVICE_STATUS_REQUEST:
      return Object.assign({}, state);
    case deviceConstants.SET_DEVICE_STATUS_SUCCESS:
      return Object.assign({}, setDeviceStatus(state, action.item));
    case deviceConstants.SET_DEVICE_STATUS_FAILURE:
      return Object.assign({}, state);

    case userConstants.GET_USER_DEVICES_SUCCESS:
      return Object.assign({}, UpdateUserDevice(state, action.payload), {loading: false, latestAction: new Date()});

//    case customerConstants.SWITCH_CUSTOMER:
//      return {}

    case userConstants.LOGOUT_REQUEST:
      return Object.assign({});

    default:
      return state
  }
}
