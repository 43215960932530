import { fileConstants, webConstants, userConstants, binderConstants, queueConstants } from '@constants/admin/';
import { cmpNum } from '@lib/simpletools';
import { BinderItemType } from '@constants/common.constants';

export var QBINDER_INITIAL_STATE = {
  id: "",
  date: "",
  binderName: "",
  position: -1,
  error: "",
  size: 0,
  uploadSize: 0,
  itemCount: 0,
  itemCountPro: 0,
  boardId: -1,
  overallPercent: 0,
  sectionPercent: 0,
  currentFile: '',
  currentFiles: null,
  converting: false,
  loading: false,
  paused: false,
  running: false,
  verifying: false,
  complete: false,
  contentType: "binder",
  taskId: "",
  data: null,
  direction: "",
  worker: null,
};

export var QFILE_INITIAL_STATE = {
  id: "",
  date: "",
  fileName: "",
  position: -1,
  error: "",
  size: 0,
  uploadSize: 0,
  boardId: -1,
  overallPercent: 0,
  converting: false,
  loading: false,
  running: false,
  paused: false,
  complete: false,
  verifying: false,
  contentType: "file",
  taskId: '',
  data: null,
  direction: "",
  worker: null,
};

function CalculateProgress(state) {
  if (state['master'] === undefined) {
    state['master'] = { id: "master" };
  }

  var total = 0;
  var current = 0;
  var errorTotal = 0;
  for (var qkey in state) {
    if (qkey === 'master') {
      continue;
    }
    const item = state[qkey];
    if (item.direction !== "upload") continue;
    total++;

    if (item.complete) {
      current++;
    }
    if (item.complete && item.error !== "")
      errorTotal++;
  }

  if (current > total) {
    //completed
    current = 0;
    total = 0;
  }
  state['master'].current = current;
  state['master'].total = total;
  state['master'].error = errorTotal;

  return state;
}

function AddFileStatus(state, data, direction = "upload") {
  var amount = Object.keys(state).filter(function (key) {
    if (key !== "master" &&
      state[key].direction === direction &&
      state[key].worker === null &&
      !state[key].complete)
      return true;
    return false;
  }).length;
  state[data.id] = Object.assign({}, QFILE_INITIAL_STATE);
  state[data.id].id = data.id;
  state[data.id].fileName = data.fileName;
  state[data.id].size = 0;
  if (data.keyList !== undefined) {
    data.keyList.forEach(function (user) {
      var userFile = data.userFiles.find(o => o.userId === user.userId);
      if (userFile) {
        if (userFile.documentId === "" || userFile.documentId === undefined)
          state[data.id].size += data.fileSize;
      } else {
        state[data.id].size += data.fileSize;
      }
    })
  }
  state[data.id].position = amount;
  if (data.hasOwnProperty('fileId'))
    state[data.id].fileId = data.fileId;
  if (data.hasOwnProperty('boardId'))
    state[data.id].boardId = data.boardId;
  if (data.hasOwnProperty('type'))
    state[data.id].type = data.type;
  if (data.hasOwnProperty('lastModified'))
    state[data.id].date = data.file.lastModified;
  if (data.hasOwnProperty('displayName'))
    state[data.id].displayName = data.displayName;
  if (data.hasOwnProperty('tag'))
    state[data.id].tag = data.tag;
  state[data.id].data = data;
  state[data.id].direction = direction;
  return state;
}

function AddDocumentStatus(state, data) {
  var amount = Object.keys(state).filter(function (key) {
    if (key !== "master" &&
      state[key].direction === 'docUpload' &&
      state[key].worker === null &&
      !state[key].complete)
      return true;
    return false;
  }).length;
  state[data.documentId] = Object.assign({}, QFILE_INITIAL_STATE);
  state[data.documentId].id = data.documentId;
  state[data.documentId].size = 0;
  state[data.documentId].position = amount;
  state[data.documentId].data = data;
  state[data.documentId].direction = 'docUpload';
  return state;
}

function AddFilesStatus(state, filestat) {
  var amount = Object.keys(state).filter(function (key) {
    if (key !== "master" &&
      state[key].direction === 'upload' &&
      state[key].worker === null &&
      !state[key].complete)
      return true;
    return false;
  }).length;
  var count = 0;
  filestat.forEach(function (data) {
    data.processType = webConstants.ADD_FILE;
    state[data.id] = Object.assign({}, QFILE_INITIAL_STATE);
    state[data.id].id = data.id;
    state[data.id].fileName = data.fileName;
    state[data.id].size = 0;
    state[data.id].boardId = data.boardId;
    if (data.keyList !== undefined) {
      data.keyList.forEach(function (user) {
        var userFile = data.userFiles.find(o => o.userId === user.userId);
        if (userFile) {
          if (userFile.documentId === "" || userFile.documentId === undefined)
            state[data.id].size += data.fileSize;
        } else {
          state[data.id].size += data.fileSize;
        }
      })
    }
    state[data.id].position = amount + count;
    count++;
    if (data.hasOwnProperty('fileId'))
      state[data.id].fileId = data.fileId;
    if (data.hasOwnProperty('boardId'))
      state[data.id].boardId = data.boardId;
    if (data.hasOwnProperty('type'))
      state[data.id].type = data.type;
    if (data.hasOwnProperty('lastModified'))
      state[data.id].date = data.file.lastModified;
    if (data.hasOwnProperty('displayName'))
      state[data.id].displayName = data.displayName;
    if (data.hasOwnProperty('taskId'))
      state[data.id].taskId = data.taskId;

    state[data.id].data = data;
    state[data.id].direction = 'upload';
  });
  return state;
}

function AddBinderStatus(state, data) {
  var amount = Object.keys(state).filter(function (key) {
    if (key !== "master" &&
      state[key].direction === 'upload' &&
      state[key].worker === null &&
      !state[key].complete)
      return true;
    return false;
  }).length;
  state[data.id] = Object.assign({}, QBINDER_INITIAL_STATE);
  state[data.id].id = data.id;
  state[data.id].binderName = data.name;
  state[data.id].itemCount = data.itemCount;
  state[data.id].data = data;
  state[data.id].position = amount;
  state[data.id].taskId = data.taskId;
  state[data.id].size = 0;
  if (data.items !== undefined) {
    data.items.forEach(function (item) {
      if (item.binderType === BinderItemType.header || item.binderType === BinderItemType.vote) return;

      if (data.processType === webConstants.PUBLISH_BINDER) {
        //Just check all users
        item.userItems.forEach(function (userItem) {
          var adminUser = data.adminUsers.find(o => o.userId === userItem.userId);
          if (adminUser) return;
          if (userItem.documentId !== undefined)
            if (userItem.documentId > 0) {
              state[data.id].size += userItem.size;
              return;
            }
          state[data.id].size += item.size;
        })
      }
      //Just check admin users
      data.adminUsers.forEach(function (userId) {
        var userItem = item.userItems.find(o => o.userId === userId);
        if (userItem) {
          if (userItem.documentId !== undefined)
            if (userItem.documentId > 0) {
              state[data.id].size += userItem.size;
              return;
            }
        }
        state[data.id].size += item.size;
      })
    })
  }
  state[data.id].direction = 'upload';

  return CalculateProgress(state);
}

function AddMinutes(state, data) {
  var amount = Object.keys(state).filter(function (key) {
    if (key !== "master" &&
      state[key].direction === 'upload' &&
      state[key].worker === null &&
      !state[key].complete)
      return true;
    return false;
  }).length;
  state[data.id] = Object.assign({}, QFILE_INITIAL_STATE);
  state[data.id].id = data.id;
  state[data.id].binderName = data.name;
  state[data.id].itemCount = data.itemCount;
  state[data.id].data = data;
  state[data.id].position = amount;
  state[data.id].taskId = data.taskId;
  state[data.id].size = 0;
  state[data.id].contentType = BinderItemType.minutes;
  /*if(data.items !== undefined){
    data.items.forEach(function(item){
      if(item.binderType === BinderItemType.header || item.binderType === BinderItemType.vote) return;

      if(data.processType === webConstants.PUBLISH_BINDER){
        //Just check all users
        item.userItems.forEach(function(userItem){
          var adminUser = data.adminUsers.find( o => o.userId === userItem.userId );
          if(adminUser) return;
          if(userItem.documentId !== undefined)
            if(userItem.documentId > 0){
              state[data.id].size += userItem.size;
              return;
            }
          state[data.id].size += item.size;
        })
      }
      //Just check admin users
      data.adminUsers.forEach(function(userId){
        var userItem = item.userItems.find( o => o.userId === userId );
        if(userItem){
          if(userItem.documentId !== undefined)
            if(userItem.documentId > 0){
              state[data.id].size += userItem.size;
              return;
            }
        }
        state[data.id].size += item.size;
      })
    })
  }*/
  state[data.id].direction = 'upload';

  return CalculateProgress(state);
}

function AddStatusFile(state, type, data) {
  var amount = Object.keys(state).filter(function (key) {
    if (key !== "master" &&
      state[key].direction === type &&
      !state[key].loading &&
      !state[key].complete)
      return true;
    return false;
  }).length;
  state[data.id] = Object.assign({}, QFILE_INITIAL_STATE);
  state[data.id].id = data.id;
  if (type === 'checkdoc') {
    state[data.id].fileName = "";
    state[data.id].size = 0;
  } else {
    state[data.id].fileName = data.name;
    state[data.id].size = data.size;
  }

  state[data.id].position = amount;
  state[data.id].data = data;
  state[data.id].direction = type;
  return state;
}

function UpdateStatus(state, data) {
  if (state[data.id] === undefined) {
    state[data.id] = Object.assign({}, QFILE_INITIAL_STATE);
    state[data.id].id = data.id;
    state[data.id].fileName = data.fileName;
    state[data.id].size = data.fileSize;
  }
  if (state['master'] === undefined) {
    state['master'] = { id: "master" };
    state['master'].current = 0;
    state['master'].total = 0;
    state['master'].type = '';
    state['master'].currentName = "";
    state['master'].currentfile = "";
    state['master'].overallPercent = 0;
    state['master'].sectionPercent = 0;
    state['master'].verfying = false;
    state['master'].error = 0;
  }

  if (data.type === webConstants.UPDATE_ENCY) {
    if (data.progress === 100) {
    }
  } else if (data.type === webConstants.UPDATE_UPLD) {
    if (data.verfying !== undefined) {
      state[data.id].verifying = true;
      if (state[data.id].direction === "upload") {
        state['master'].verfying = true;
      }
    } else if (data.progress === 100) {
      state[data.id].loading = true;
      state[data.id].complete = true;
      state[data.id].running = false;
      if (state[data.id].contentType === "binder") {
        state[data.id].overallPercent = 100;
        state[data.id].sectionPercent = 100;
        //state[data.id].currentFiles = null;
        state[data.id].currentFile = '';
        if (state[data.id].direction === "upload") state['master'].sectionPercent = 100;
      } else {
        state[data.id].overallPercent = 100;
      }
      if (state[data.id].direction === "upload") state['master'].overallPercent = 100;
    } else {
      if (state[data.id].contentType === "binder") {
        var o = data.progress;
        //if(data.progresFiles !== undefined)
        //  state[data.id].currentFiles = data.progresFiles;
        var s = data.progressFile;

        var n = data.fileName !== "" && data.fileName !== undefined ? data.fileName : '';
        if (n === "" && data.fileDisplay !== "" && data.fileDisplay !== undefined) n = data.fileDisplay;

        if (data.converting !== undefined) {
          state[data.id].overallPercent = o;
          state[data.id].sectionPercent = data.progressConverting;
          state[data.id].currentFiles = n;
          state[data.id].converting = true;
          return state;
        }
        state[data.id].converting = false;

        if (n === state[data.id].currentfile && state[data.id].overallPercent > o && o > 0 && o < 100 && o !== undefined)
          o = state[data.id].overallPercent;
        if (n === state[data.id].currentfile && state[data.id].sectionPercent > s && s > 0 && s < 100 && s !== undefined)
          s = state[data.id].sectionPercent;
        //console.log('updateFromWorker progress', data.progress,o,s);

        if (state[data.id].direction === "upload") {
          state['master'].type = data.style;
          state['master'].currentfile = n;
          state['master'].currentId = data.id;
          state['master'].overallPercent = o;
          state['master'].sectionPercent = s;
          if (data.itemProgressCount !== undefined)
            state[data.id].itemCountPro = data.itemProgressCount;
        }

        state[data.id].overallPercent = o;
        state[data.id].sectionPercent = s;
        state[data.id].currentFile = n;
      } else {
        if (state[data.id].overallPercent < data.progress) {
          state[data.id].overallPercent = data.progress;
          if (state[data.id].direction === "upload") {
            state['master'].overallPercent = data.progress;
            state['master'].sectionPercent = data.progress;
          }
        }
      }
    }
  }

  return state;
}

function CompeleteConversion(state, item) {
  if (state[item.id] !== undefined) {
    state[item.id].worker = null;
    state[item.id].position = -1;
    state[item.id].complete = true;
    state[item.id].loading = false;
    state[item.id].running = false;
    state[item.id].data = item;

    var List = Object.keys(state).filter(function (key) {
      if (key !== "master" &&
        state[key].direction === "doctopdf" &&
        state[key].complete === false &&
        state[key].loading === false
      )
        return true;
      return false;
    }).map(function (key) { return state[key]; });
    List.sort(function (a, b) {
      return cmpNum(a.position, b.position);
    })
    for (var x = 0; x < List.length; x++) {
      List[x].position = x;
    }
  }

  return state;
}

function CompeleteStatus(state, id, nodelete = false) {
  if (state[id] !== undefined){
    state[id].worker = null;
    state[id].complete = true;    
  }
  if(nodelete === false)
    delete state[id];

  if (state['master'] === undefined) {
    state['master'] = { id: "master" };
  }

  if (Object.keys(state).length === 1) {
    state['master'].current = 0;
    state['master'].total = 0;
    state['master'].type = '';
    state['master'].currentfile = "";
    state['master'].currentName = "";
    state['master'].currentId = "";
    state['master'].overallPercent = 0;
    state['master'].sectionPercent = 0;
    state['master'].verfying = false;
    state['master'].error = 0;
  } else {
    state = CalculateProgress(state);
  }
  return state;
}

function QueueCancelItemError(state, item) {
  if (state[item.id] !== undefined) {
    var amount = Object.keys(state).filter(function (key) {
      if (key !== "master" &&
        state[key].direction === 'upload' &&
        state[key].worker === null &&
        !state[key].complete)
        return true;
      return false;
    }).length;
    state[item.id].worker = null;
    state[item.id].position = amount;
    state[item.id].loading = false;
    state[item.id].running = false;
    state[item.id].error = item.error;
    state[item.id].loading = false;
    state[item.id].converting = true;
    state[item.id].complete = true;

    if (item.hasOwnProperty('binderData')) {
      state[item.id].data = item.binderData;
    }
  }

  return CalculateProgress(state);
}

function ErrorStatus(state, item) {
  /*if(state[item.id] === undefined){
    if(item.hasOwnProperty('fileName')){
      state[item.id] = Object.assign({}, QFILE_INITIAL_STATE);
      state[item.id].fileName = item.fileName;
    }else{
      state[item.id] = Object.assign({}, QBINDER_INITIAL_STATE);
      state[item.id].binderName = item.binderName;
    }
    state[item.id].id = item.id;
  }*/
  if (state[item.id] === undefined) return state;
  state[item.id].error = item.error;
  state[item.id].loading = false;
  state[item.id].converting = true;
  state[item.id].complete = true;
  if (item.hasOwnProperty('binderData'))
    state[item.id].data = item.binderData;
  /*TODO Add retry back in*/
  if (item.hasOwnProperty('retry'))
    if (item.retry === true || item.retry === 1)
      state[item.id].complete = false;
  if (item.clearWorker !== undefined)
    if (item.clearWorker)
      state[item.id].worker = null;

  return CalculateProgress(state);
}

function QueueDetails(state, item) {
  if (state['master'] === undefined) {
    state['master'] = { id: "master" };
  }

  state['master'].current = item.current;
  state['master'].total = item.total;
  state['master'].type = item.type;
  state['master'].currentName = item.currentName;
  state['master'].currentfile = item.currentfile;
  state['master'].currentId = "";
  state['master'].overallPercent = item.overallPercent;
  state['master'].sectionPercent = item.sectionPercent;
  state['master'].verfying = item.verfying;
  state['master'].error = 0;

  return state;
}

function SwapQueue(state, items) {
  var List = Object.keys(state).filter(function (key) {
    if (key !== "master")
      return true;
    return false;
  }).map(function (key) { return state[key]; });
  List.sort(function (a, b) {
    return cmpNum(a.position, b.position);
  })

  const [removed] = List.splice(items.startIndex, 1);

  List.splice(items.endIndex, 0, removed);
  for (var x = 0; x < List.length; x++) {
    List[x].position = x;

    if (x !== 0) {
      if (List[x].worker !== null) {
        List[x].worker.terminate();
        List[x].worker = null;
        List[x].loading = false;
        List[x].running = false;
      }
    }
  }
  return state;
}

function QueueStartItem(state, id) {
  if (state[id] !== undefined) {
    if (state[id].direction === "upload") {
      state = CalculateProgress(state);
      state['master'].type = state[id].data.processType;
      if (state[id].contentType === "binder") {
        state['master'].currentName = state[id].binderName;
        state['master'].currentfile = '';
      } else {
        state['master'].currentName = state[id].fileName;
        state['master'].currentfile = state[id].fileName;
      }
      state['master'].currentId = id;
      state['master'].overallPercent = 0;
      state['master'].sectionPercent = 0;
      state['master'].verfying = false;
    }

    state[id].overallPercent = 0;
    state[id].sectionPercent = 0;
    state[id].loading = true;
    if (!state[id].paused)
      state[id].running = true;
  }

  return state;
}

function QueueCancelItem(state, id) {
  if (state[id] !== undefined) {
    var amount = Object.keys(state).filter(function (key) {
      if (key !== "master" &&
        state[key].direction === 'upload' &&
        state[key].worker === null &&
        !state[key].complete)
        return true;
      return false;
    }).length;
    state[id].worker = null;
    state[id].position = amount;
    state[id].loading = false;
    state[id].running = false;
  }

  return state;
}

function QueueCompleteItem(state, id) {
  if (state[id] !== undefined) {
    var amount = Object.keys(state).filter(function (key) {
      if (key !== "master" &&
        state[key].direction === 'upload' &&
        state[key].worker === null &&
        !state[key].complete)
        return true;
      return false;
    }).length;
    state[id].worker = null;
    state[id].position = amount;
    state[id].complete = true;
    state[id].loading = false;
    state[id].running = false;
  }

  return state;
}

function PauseResumeItem(state, id, running, isPaused) {
  if (state[id] !== undefined) {
    state[id].paused = isPaused;
    state[id].position = 100;
    if (state[id].worker !== null && running)
      state[id].running = false;

    //recalulated the position order
    var List = Object.keys(state).filter(function (key) {
      if (key !== "master")
        return true;
      return false;
    }).map(function (key) { return state[key]; });
    List.sort(function (a, b) {
      return cmpNum(a.position, b.position);
    })
    for (var x = 0; x < List.length; x++) {
      List[x].position = x;
    }
  }

  return state;
}

function RetryItem(state, id) {
  if (state[id] !== undefined) {
    state[id].worker = null;
    state[id].complete = false;
    state[id].error = "";
    state[id].loading = false;
    state[id].running = false;
  }

  return state;
}

function DocumentQueue(state, item){
  if(state["documentUpload"] === undefined)
    state["documentUpload"] = {}
  state["documentUpload"] = item
  return state
}

export function uploadQueue(state = {}, action) {
  switch (action.type) {
    case queueConstants.ADD_QUEUE_DOCUMENT_REQUEST:
      return Object.assign({}, AddDocumentStatus(state, action.fileitem));
    case queueConstants.ADD_QUEUE_FILES_REQUEST:
      return Object.assign({}, AddFilesStatus(state, action.fileitem));
    case queueConstants.ADD_QUEUE_FILE_REQUEST:
      return Object.assign({}, AddFileStatus(state, action.fileitem));
    case queueConstants.ADD_QUEUE_BINDER_REQUEST:
      return Object.assign({}, AddBinderStatus(state, action.binderItem));
    case queueConstants.ADD_QUEUE_TEMPLATE_REQUEST:
      return Object.assign({}, AddBinderStatus(state, action.templateItem));
    case queueConstants.ADD_QUEUE_CHECKDOC_REQUEST:
      return Object.assign({}, AddStatusFile(state, 'checkdoc', action.items));
    case queueConstants.ADD_QUEUE_MINUTES_REQUEST:
      return Object.assign({}, AddMinutes(state, action.minutes));

    case queueConstants.ADD_QUEUE_CONVERT_REQUEST:
      return Object.assign({}, AddStatusFile(state, 'doctopdf', action.fileitem));

    case queueConstants.ADD_QUEUE_PDF_REQUEST:
      return Object.assign({}, AddStatusFile(state, 'pdfmod', action.fileitem));

    case queueConstants.MERGE_QUEUE_PDF_REQUEST:
      return Object.assign({}, AddStatusFile(state, 'pdfmerge', action.fileitem));

    case queueConstants.DOWNLOAD_QUEUE_FILE_REQUEST:
      return Object.assign({}, AddFileStatus(state, action.fileitem, "download"));
    case queueConstants.DOWNLOAD_QUEUE_DOCUMENT_REQUEST:
      return Object.assign({}, AddFileStatus(state, action.documentitem, "download"));

    //case fileConstants.UPDATE_STATS_FILE_REQUEST:
    //  return Object.assign({}, UpdateStatus(state, action.fileitem));
    //    case binderConstants.UPDATE_STATS_BINDER_REQUEST:
    //      return Object.assign({}, UpdateStatus(state, action.data));
    //    case binderConstants.UPDATE_STATS_TEMPLATE_REQUEST:
    //      return Object.assign({}, UpdateStatus(state, action.data));
    case queueConstants.PROGRESS_QUEUE_ITEM_REQUEST:
      return Object.assign({}, UpdateStatus(state, action.data));

    //case binderConstants.SET_BINDER_ERROR_REQUEST:
    //case fileConstants.SET_FILE_ERROR_REQUEST:
    case queueConstants.ERROR_QUEUE_ITEM_REQUEST:
      return Object.assign({}, ErrorStatus(state, action.item));

    case queueConstants.REMOVE_FILE_REQUEST:
      return Object.assign({}, CompeleteStatus(state, action.id));
    //case fileConstants.COMPLETE_UPLOAD_FILE_REQUEST:
    //  return Object.assign({}, CompeleteStatus(state, action.fileitem.id));
    //case fileConstants.COMPLETE_DOWNLOAD_FILE_REQUEST:
    //  return Object.assign({}, CompeleteStatus(state, action.fileitem.id));
    //case binderConstants.COMPLETE_DOWNLOAD_DOCUMENT_REQUEST:
    //  return Object.assign({}, CompeleteStatus(state, action.documentitem.id));
    //case binderConstants.COMPLETE_UPLOAD_BINDER_REQUEST:
    //  return Object.assign({}, CompeleteStatus(state, action.data.id));

    case queueConstants.CANCEL_QUEUE_ITEM_REQUEST:
      return Object.assign({}, QueueCancelItem(state, action.id));
    case queueConstants.CANCEL_QUEUEERROR_ITEM_REQUEST:
      return Object.assign({}, QueueCancelItemError(state, action.item));

    case queueConstants.START_QUEUE_ITEM_REQUEST:
      return Object.assign({}, QueueStartItem(state, action.id));

    case queueConstants.PAUSE_QUEUE_ITEM_REQUEST:
      return Object.assign({}, PauseResumeItem(state, action.id, action.running, true));
    case queueConstants.RESUME_QUEUE_ITEM_REQUEST:
      return Object.assign({}, PauseResumeItem(state, action.id, action.running, false));
    case queueConstants.RETRY_QUEUE_ITEM_REQUEST:
      return Object.assign({}, RetryItem(state, action.id));

    case queueConstants.COMPLETE_QUEUE_ITEM_REQUEST:
      return Object.assign({}, CompeleteStatus(state, action.id));
    //return Object.assign({}, QueueCompleteItem(state, action.id));
    case queueConstants.COMPLETE_QUEUE_NODELETE_REQUEST:
      return Object.assign({}, CompeleteStatus(state, action.id, true));

    case queueConstants.COMPLETE_CONVERT_ITEM_REQUEST:
      return Object.assign({}, CompeleteConversion(state, action.item));

    case queueConstants.UPDATE_QUEUE_REQUEST:
      return Object.assign({}, QueueDetails(state, action.item));

    case queueConstants.SWAP_QUEUE_REQUEST:
      return Object.assign({}, SwapQueue(state, action.items));

    case binderConstants.SAVE_BINDERTRANSACTIONEVENT_REQUEST:
      return Object.assign({}, DocumentQueue(state, {upload: true}));
    case binderConstants.SAVE_BINDERTRANSACTIONEVENT_SUCCESS:
      return Object.assign({}, DocumentQueue(state, {upload: false}));
    case binderConstants.SAVE_BINDERTRANSACTIONEVENT_FAILURE:
      return Object.assign({}, DocumentQueue(state, {upload: false}));

    case userConstants.LOGOUT_REQUEST:
      return Object.assign({});
    default:
      return state
  }
}
