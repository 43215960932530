export const contributorReducerConstants = {
    POPULATE_CONTRIBUTORS: 'POPULATE_CONTRIBUTORS',
    CREATE_CONTRIBUTORS_STATE: 'CREATE_CONTRIBUTORS_STATE',
    ADD_CONTRIBUTOR: 'ADD_CONTRIBUTOR',
    UPDATE_CONTRIBUTOR: 'UPDATE_CONTRIBUTOR',
    DELETE_CONTRIBUTOR: 'DELETE_CONTRIBUTOR',
    POPULATE_CONTRIBUTOR_FILES: 'POPULATE_CONTRIBUTOR_FILES',
    POPULATE_CONTRIBUTOR_FILE: 'POPULATE_CONTRIBUTOR_FILE',
    UPDATE_CONTRIBUTOR_FILE: 'UPDATE_CONTRIBUTOR_FILE',
    DELETE_CONTRIBUTOR_FILE: 'DELETE_CONTRIBUTOR_FILE',
    SET_ANCHOR_ELEMENT: 'SET_ANCHOR_ELEMENT',
    SET_SHOW_INBOX: 'SET_SHOW_INBOX',
    CLEAR_SELECTED_NOTIFICATIONS: 'CLEAR_SELECTED_NOTIFICATIONS',
    DESELECT_NOTIFICATION: 'DESELECT_NOTIFICATION',
    TOGGLE_SELECTED_NOTIFICATION: 'TOGGLE_SELECTED_NOTIFICATION',

    SET_LOADING: 'SET_LOADING',

    UPDATE_CONTRIBUTOR_MEMBERSHIP: 'UPDATE_CONTRIBUTOR_MEMBERSHIP',
    DELETE_CONTRIBUTOR_MEMBERSHIP: 'DELETE_CONTRIBUTOR_MEMBERSHIP',
    UPDATE_CONTRIBUTOR_MEMBERSHIPS: 'UPDATE_CONTRIBUTOR_MEMBERSHIPS',
}

var initialState = {
    anchorEl: null,
    showInbox: false,
    contributors: {},
    isLoading: {},
    files: {},
    selectedNotifications: {},
    contributorMemberships: {}
}

function deleteContributorMembership(state, membership) {
    var newState = { ...state };
    try {
        delete newState.contributorMemberships[membership.contributorId][membership.boardId];
    } catch { }
    return newState;
}

function updateContributorMemberships(state, memberships) {
    var newState = { ...state };
    for (var m of memberships) {
        newState = updateContributorMembership(newState, m);
    }
    return newState;
}

function updateContributorMembership(state, membership) {
    var newState = { ...state };
    try {
        if (!newState.contributorMemberships[membership.contributorId]) { newState.contributorMemberships[membership.contributorId] = {} }
        newState.contributorMemberships[membership.contributorId][membership.boardId] = membership;
    } catch { }
    return newState;
}

function updateContributorFile(state, id, customerId, properties = {}) {
    var newState = { ...state }
    try {
        if (newState.files[customerId]) {
            var itemIndex = newState.files[customerId].findIndex(f => f.id == id);
            if (itemIndex < 0) { return newState; }
            var newFile = newState.files[customerId][itemIndex];
            if (newFile) {
                Object.keys(properties).forEach(k => {
                    newFile[k] = properties[k];
                });
                newState.files[customerId][itemIndex] = { ...newFile };
                newState.files[customerId] = [...newState.files[customerId]];
            }
        }
    } catch { }

    return newState;
}

function deselectNotification(state, notificationId) {
    var newState = { ...state };
    try {
        delete newState.selectedNotifications[notificationId];
    } catch { }
    newState.selectedNotifications = { ...newState.selectedNotifications };
    return newState;
}

function clearSelectedNotifications(state) {
    var newState = { ...state };
    newState.selectedNotifications = {};
    return newState;
}

function toggleNotificationSelect(state, notifcationId) {
    var newState = { ...state };
    if (newState.selectedNotifications[notifcationId]) {
        delete newState.selectedNotifications[notifcationId];
    } else {
        newState.selectedNotifications[notifcationId] = true;
    }
    newState.selectedNotifications = { ...newState.selectedNotifications };
    return newState;
}

function createContributorsState(state, customerId) {
    if (!customerId) { return state; }
    var newState = { ...state };
    if (!newState.contributors[customerId]) { newState.contributors[customerId] = {}; }
    if (!newState.files[customerId]) { newState.files[customerId] = []; }
    newState.contributors = { ...newState.contributors };
    newState.files = { ...newState.files };
    return newState;
}

function populateContributorFiles(state, customerId, contributorFiles) {
    if (!customerId) { return state; }
    var newState = createContributorsState(state, customerId);
    try {
        newState.files[customerId] = [...contributorFiles];
    } catch { }

    return newState;
}

function populateContributorFile(state, customerId, contributorFile) {
    if (!customerId) { return state; }
    var newState = createContributorsState(state, customerId);
    try {
        if(newState.files[customerId]){ 
            newState.files[customerId] = [...newState.files[customerId], contributorFile];
        } else {
            newState.files[customerId] = [contributorFile]
        }
    } catch { }
    
    return newState;
}

function populateContributors(state, customerId, contributors) {
    if (!customerId) { return state; }
    var newState = createContributorsState(state, customerId);

    for (var c of contributors) {
        if (!newState.contributors[c.customerId]) { newState.contributors[c.customerId] = {}; }
        newState.contributors[c.customerId][c.id] = c;
    }

    return newState;
}

function addContributor(state, customerId, contributor) {
    var newState = createContributorsState(state, customerId);

    newState.contributors[customerId][contributor.id] = { ...contributor };
    newState.contributors[customerId] = { ...newState.contributors[customerId] }

    return newState;
}

function updateContributor(state, customerId, contributor) {
    var newState = createContributorsState(state, customerId);

    newState.contributors[customerId][contributor.id] = { ...contributor };
    newState.contributors[customerId] = { ...newState.contributors[customerId] }

    return newState;
}

function deleteContributor(state, customerId, contributor) {
    var newState = createContributorsState(state, customerId);

    delete newState.contributors[customerId][contributor.id];
    newState.contributors[customerId] = { ...newState.contributors[customerId] }

    return newState;
}

function deleteContributorFile(state, customerId, fileId) {
    var newState = createContributorsState(state, customerId);
    try {
        newState.files[customerId] = newState.files[customerId].filter(f => f.id != fileId);
    } catch { }
    return newState;
}

export function contributors(state = initialState, action) {
    switch (action.type) {
        case contributorReducerConstants.SET_SHOW_INBOX:
            return { ...state, showInbox: action.payload };
        case contributorReducerConstants.SET_ANCHOR_ELEMENT:
            return { ...state, anchorEl: action.payload };
        case contributorReducerConstants.POPULATE_CONTRIBUTOR_FILES:
            return populateContributorFiles(state, action.payload.customerId, action.payload.contributorFiles);
        case contributorReducerConstants.POPULATE_CONTRIBUTOR_FILE:
            return populateContributorFile(state, action.payload.customerId, action.payload.contributorFile);
        case contributorReducerConstants.CREATE_CONTRIBUTORS_STATE:
            return createContributorsState(state, action.payload.customerId);
        case contributorReducerConstants.POPULATE_CONTRIBUTORS:
            return populateContributors(state, action.payload.customerId, action.payload.contributors);
        case contributorReducerConstants.ADD_CONTRIBUTOR:
            return addContributor(state, action.payload.customerId, action.payload.contributor)
        case contributorReducerConstants.UPDATE_CONTRIBUTOR:
            return updateContributor(state, action.payload.customerId, action.payload.contributor)
        case contributorReducerConstants.DELETE_CONTRIBUTOR:
            return deleteContributor(state, action.payload.customerId, action.payload.contributor);
        case contributorReducerConstants.UPDATE_CONTRIBUTOR_FILE:
            return updateContributorFile(state, action.payload.id, action.payload.customerId, action.payload.properties);
        case contributorReducerConstants.DELETE_CONTRIBUTOR_FILE:
            return deleteContributorFile(state, action.payload.customerId, action.payload.fileId);
        case contributorReducerConstants.TOGGLE_SELECTED_NOTIFICATION:
            return toggleNotificationSelect(state, action.payload.id);
        case contributorReducerConstants.DESELECT_NOTIFICATION:
            return deselectNotification(state, action.payload.id);
        case contributorReducerConstants.CLEAR_SELECTED_NOTIFICATIONS:
            return clearSelectedNotifications(state);
        case contributorReducerConstants.UPDATE_CONTRIBUTOR_MEMBERSHIPS:
            return updateContributorMemberships(state, action.payload);
        case contributorReducerConstants.DELETE_CONTRIBUTOR_MEMBERSHIP:
            return deleteContributorMembership(state, action.payload);
        case contributorReducerConstants.UPDATE_CONTRIBUTOR_MEMBERSHIP:
            return updateContributorMembership(state, action.payload);
        case contributorReducerConstants.SET_LOADING:
            return { ...state, isLoading: { ...state.isLoading, [action.payload.id]: { loading: action.payload.loading, loaded: action.payload.loaded } } };
        default:
            return state;
    }
}