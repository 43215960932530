import { adminPermissionsConstants } from "../../constants/admin";
import { v4 as uuidv4 } from 'uuid';
import { adminConflictOfInterestConstants } from "../../constants/admin/conflictOfInterests.constants";

var INITIAL_STATE = {}

function setConflictOfInterests(state, boardId, conflictOfInterests) {
    var newState = { ...state };
    newState[boardId] = [...conflictOfInterests];
    return newState;
}

function addConflictOfInterest(state, payload) {
    var newState = { ...state };
    var boardId = payload.boardId;
    
    if (newState[boardId]) {
        newState[boardId] = [...newState[boardId]];
        newState[boardId].push(payload);
    } else {
        newState[boardId] = [payload];
    }

    return newState;
}

function removeConflictOfInterest(state, boardId, coiID) {
    var newState = { ...state };
    if (newState[boardId]) {
        var index = newState[boardId].findIndex(p => p.id == coiID);
        if (index > -1) {
            newState[boardId] = [...newState[boardId]];
            newState[boardId].splice(index, 1);
        }
    }
    return newState;
}

function updateConflictOfInterest(state, boardId, coiID, newProperties = {}) {
    if (!boardId || !coiID || !state[boardId]) { return state; }

    var newState = { ...state };
    var existingCOI = newState[boardId].find(p => p.id == coiID);

    if (!existingCOI) {
        return state;
    }

    Object.keys(newProperties).forEach(prop => {
        existingCOI[prop] = newProperties[prop];
    });

    newState[boardId] = [...newState[boardId]];

    return newState;
}

export function adminConflictOfInterest(state = INITIAL_STATE, action) {
    switch (action.type) {
        case adminConflictOfInterestConstants.SET_CONFLICT_OF_INTERESTS:
            return setConflictOfInterests(state, action.payload.boardId, action.payload.conflictOfInterests);
        case adminConflictOfInterestConstants.ADD_CONFLICT_OF_INTERESTS:
            return addConflictOfInterest(state, action.payload);
        case adminConflictOfInterestConstants.REMOVE_CONFLICT_OF_INTERESTS:
            return removeConflictOfInterest(state, action.payload.boardId, action.payload.id);
        case adminConflictOfInterestConstants.UPDATE_CONFLICT_OF_INTERESTS:
            return updateConflictOfInterest(state, action.payload.boardId, action.payload.id, { ...action.payload.properties });
        default:
            return state;
    }
}