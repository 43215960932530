import { boardConstants, binderConstants, userConstants, customerConstants } from '@constants/admin/';

var USAGE_INITIAL_STATE = {
  id:"",
  loading: true,
  error:"",
};

function PopulateBinderUsage(state, item){
  if(item.hasOwnProperty('usage')){
    if(state.data === undefined) state.data = {};
    item.usage.forEach(function(object){
      if(state.data[object.itemId] === undefined){
        state.data[object.itemId] = {};
      }
      if(state.data[object.itemId][object.id] === undefined){
        state.data[object.itemId][object.id] = {};
      }
      Object.keys(object).map(e => state.data[object.itemId][object.id][e] = object[e]);
    });
  }

  return state;
}

function PopulateAllBinder(state, items){
  for(var key in items){
    for(var x=0; x<items[key].length; x++){
      state = PopulateBinderUsage(state, items[key][x]);
    };
  }
  return state;
}

function UpdateUsage(state, payload, id){
  payload.forEach(function(object){
    if(state.data[object.itemId] === undefined){
      state.data[object.itemId] = {};
    }
    if(state.data[object.itemId][object.id] === undefined){
      state.data[object.itemId][object.id] = {};
    }
    Object.keys(object).map(e => state.data[object.itemId][object.id][e] = object[e]);
  });
  return state;
}

export function binderUsage(state = {data:{}}, action) {
  switch (action.type) {
    case binderConstants.POPULATE_BINDERCONTENT_SUCCESS:
      return Object.assign({}, PopulateBinderUsage(state, action.item), {latestAction: new Date()});
    case boardConstants.POPULATE_BINDERS_SUCCESS:
      return Object.assign({}, PopulateAllBinder(state, action.data), {latestAction: new Date()});
    case binderConstants.POPULATE_TEMPLATECONTENT_SUCCESS:
      return Object.assign({}, PopulateBinderUsage(state, action.item), {latestAction: new Date()});
    case binderConstants.GET_BINDERUSAGE_SUCCESS:
      return Object.assign({}, UpdateUsage(state, action.payload, action.id), {latestAction: new Date()});

    case customerConstants.SWITCH_CUSTOMER:
      return {data:{}}
    case userConstants.LOGOUT_REQUEST:
      return Object.assign({});
    default:
      return state
  }
}
