import { fileConstants, userConstants, binderConstants, boardConstants, customerConstants } from '@constants/admin/';
import * as CrytpoLib from '@lib/cryptojs';
import { BLANK_GUID } from '@lib/simpletools';
import { BinderStatus} from '@constants/common.constants';

export var FILE_INITIAL_STATE = {
  id:"",
  documentId:"",
  tag:"",
  date:"",
  updateDate:"",
  displayName:"",
  type:"",
  status:"",
  fileName:"",
  fileSize:"",
  key: "",
  error: "",
  published: false,
  fetch:false,
  loading: false,
  data: null,
  userFiles: [],
};


function SetLoadingSateImage(state, data){
  var newstate = state;
  if(newstate[data] === undefined)
    newstate[data] = Object.assign({}, FILE_INITIAL_STATE);
  newstate[data].loading = true;
  newstate[data].error = '';
  newstate[data].documentId = data;
  newstate[data].id = data;
  return newstate;
}

function PopulateFileImage(state, data){
  if(state[data.id] === undefined)
    state[data.id] = Object.assign({}, FILE_INITIAL_STATE);
  state[data.id].loading = false;
  state[data.id].fetch = true;
  state[data.id].error = '';
  if(data.image.byteLength < 1){
    state[data.id].fetch = false;
    state[data.id].error = 'no data';
  }else{
    state[data.id].data = data.image;
    state[data.id].type = 'image/png';
  }
  return state;
}

function SetErrorFile(state, Iid, error){
  if(state[Iid] === undefined){
    state[Iid] = Object.assign({}, FILE_INITIAL_STATE);
    state[Iid].id = Iid;
  }
  state[Iid].loading = false;
  state[Iid].fetch = false;
  state[Iid].error = error;
  return state;
}

function SetError(state, Iid, error){
  if(error !== undefined && Iid !== undefined && state[Iid] !== undefined)
    state[Iid].error = error;
  return state;
}

function SetErrors(state, Iids, error){
  Iids.forEach(Iid => {
    if(error !== undefined && Iid !== undefined && state[Iid] !== undefined)
      state[Iid].error = error;
  });
  return state;
}

function SetInitialFileStatus(state, fileid){
  if(state[fileid] === undefined)
    state[fileid] = Object.assign({}, FILE_INITIAL_STATE);
  state[fileid].loading = true;
  state[fileid].id = fileid;
  state[fileid].error = '';
  return state;
}

function PopulateFileStatus(state, filestat){
  if(state[filestat.id] === undefined){
    state[filestat.id] = Object.assign({}, FILE_INITIAL_STATE);
    state[filestat.id].id = filestat.id;
    state[filestat.id].error = '';
  }
  state[filestat.id].loading = false;
  state[filestat.id].fetch = true;
  if(filestat.hasOwnProperty('creationDate'))
    state[filestat.id].date = filestat.creationDate;
  if(filestat.hasOwnProperty('date'))
    state[filestat.id].date = filestat.date;
  if(filestat.hasOwnProperty('updateDate'))
    state[filestat.id].updateDate = filestat.updateDate;
  if(filestat.hasOwnProperty('displayName'))
    state[filestat.id].displayName = filestat.displayName;
  if(filestat.hasOwnProperty('documentId'))
    state[filestat.id].documentId = filestat.documentId;
  if(filestat.hasOwnProperty('tag'))
    state[filestat.id].tag = filestat.tag;
  if(filestat.hasOwnProperty('type'))
    state[filestat.id].type = filestat.type;
  if(filestat.hasOwnProperty('status'))
    state[filestat.id].status = filestat.status;
  if(filestat.hasOwnProperty('filename'))
    state[filestat.id].fileName = filestat.filename;
  if(filestat.hasOwnProperty('fileName'))
    state[filestat.id].fileName = filestat.fileName;
  if(filestat.hasOwnProperty('fileSize'))
    state[filestat.id].fileSize = filestat.fileSize;
  if(filestat.hasOwnProperty('userFiles')){
    var f = filestat.userFiles.find(o => o.userId === BLANK_GUID)
    if(f && state[filestat.id].documentId === ""){
      state[filestat.id].documentId = f.documentId
      state[filestat.id].key = f.key
      state[filestat.id].fileSize = f.fileSize
      state[filestat.id].genseckey = true
    }
    state[filestat.id].userFiles = filestat.userFiles;
  }
  
  if (filestat.hasOwnProperty('excludeFromFileLimit')) {
    state[filestat.id].excludeFromFileLimit = filestat.excludeFromFileLimit;
  }
  if (filestat.hasOwnProperty('disallowDelete')) {
    state[filestat.id].disallowDelete = filestat.disallowDelete;
  }
  if (filestat.hasOwnProperty('disallowRename')) {
    state[filestat.id].disallowRename = filestat.disallowRename;
  }

  if(filestat.hasOwnProperty('key'))
    state[filestat.id].key = filestat.key;
  if(filestat.hasOwnProperty(BinderStatus.published))
    state[filestat.id].published = filestat.published;

  return state;
}

function AddBoardFilesList(state, items){
  items.files.forEach(function(o){
    state = PopulateFileStatus(state, o);
  });

  return state;
}

function UpdateFilesDetail(state, files){
  files.forEach(function(item){
    if(item.fileId !== ""){
      state = UpdateFileDetail(state, item);
    }
  })

  return state;
}

function UpdateFileDetail(state, fileitem){
  if (!fileitem.id) { return state; }
  if(fileitem.id !== "" && state[fileitem.id] === undefined){
    state[fileitem.id] = Object.assign({}, FILE_INITIAL_STATE);
    state[fileitem.id].loading = false;
    state[fileitem.id].id = fileitem.id;
    state[fileitem.id].error = '';
  }
  if(fileitem.hasOwnProperty('updating'))
    state[fileitem.id].updating = fileitem.updating;
  if(fileitem.hasOwnProperty('tag'))
    state[fileitem.id].tag = fileitem.tag;
  if(fileitem.hasOwnProperty('displayName'))
    state[fileitem.id].displayName = fileitem.displayName;
  if(fileitem.hasOwnProperty('creationDate'))
    state[fileitem.id].date = fileitem.creationDate;
  if(fileitem.hasOwnProperty('updateDate'))
    state[fileitem.id].updateDate = fileitem.updateDate;
  if(fileitem.hasOwnProperty('type'))
    state[fileitem.id].type = fileitem.type;
  if(fileitem.hasOwnProperty('status'))
    state[fileitem.id].status = fileitem.status;
  if(fileitem.hasOwnProperty('fileName'))
    state[fileitem.id].fileName = fileitem.fileName;
  if(fileitem.hasOwnProperty('fileSize'))
    state[fileitem.id].fileSize = fileitem.fileSize;
  if(fileitem.hasOwnProperty('demopublished'))
   state[fileitem.id].published = fileitem.demopublished;
  if(fileitem.hasOwnProperty('data'))
    state[fileitem.id].data = fileitem.data;
  if(fileitem.hasOwnProperty(BinderStatus.published))
    state[fileitem.id].published = fileitem.published;
  return state;
}

function UpdateFileDownload(state, fileitem){
  if(fileitem.id !== ""){
    if(state[fileitem.id] === undefined){
      state[fileitem.id] = Object.assign({}, FILE_INITIAL_STATE);
      state[fileitem.id].loading = false;
      state[fileitem.id].id = fileitem.id;
      state[fileitem.id].error = '';
    }
    if(fileitem.hasOwnProperty('data'))
      state[fileitem.id].data = fileitem.data;
    if(fileitem.hasOwnProperty('documentId'))
      state[fileitem.id].documentId = fileitem.documentId;

    state[fileitem.id].fetch = true;
    state[fileitem.id].loading = false;
  }
  return state;
}

function RemoveBinderImage(state, binderItem) {
  try {
    state[binderItem.imageId].data = '';
    state[binderItem.imageId].type = "";
  } catch {}
  return state;
}

function UpdateBinderImage(state, binderItem) {
  if (binderItem.hasOwnProperty('imageId')) {
    if (state[binderItem.imageId] === undefined) {
      state[binderItem.imageId] = Object.assign({}, FILE_INITIAL_STATE);
      state[binderItem.imageId].loading = false;
      state[binderItem.imageId].id = binderItem.imageId;
      state[binderItem.imageId].error = '';
    }
    state[binderItem.imageId].data = CrytpoLib.arrayBufferToBase64String(binderItem.image);
    state[binderItem.imageId].type = "image/png";
  }
  return state;
}

function SetFileDeleting(state, fileId) {
  if (state[fileId]) {
    state[fileId].isDeleting = true;
  }
  return state;
}

function DeleteFile(state, fileId) {
  if (state[fileId]) {
    state[fileId].isDeleted = true;
  }
  return state;
}

function DeleteFiles(state, fileIds) {
  fileIds.forEach((fileId) => {
    DeleteFile(state,fileId);
  });
  return state;
}

function PopulateReport(state, pdf, id){
  if(state[id] === undefined){
    state[id] = Object.assign({}, FILE_INITIAL_STATE);
    state[id].id = id;
    state[id].error = '';
  }

  state[id].loading = false;
  state[id].fetch = true;

  state[id].userId = id;
  state[id].data = pdf;

  return state;
}

function PopulateReports(state, item){
  var id = item.id;
  if(id === '') id = item.guid;
  if(state[id] === undefined){
    state[id] = Object.assign({}, FILE_INITIAL_STATE);
    state[id].id = id;
    state[id].error = '';
  }
  state[id].loading = false;
  state[id].fetch = true;

  state[id].guid = item.guid;
  state[id].userId = item.id;
  state[id].data = item.pdf;
  state[id].error = item.error;
  state[id].username = item.username;

  return state;
}

function populateAuditLogs(state, doneItem){
  if(doneItem.files !== undefined){
    for(var key in doneItem.files){
      var c = doneItem.files[key];
      state = PopulateFileStatus(state, c);
    }
  }

  return state;
}

function RenameFolder(state, oldTag, newTag) {
  Object.keys(state).forEach((key) => {
    if (state[key].tag === oldTag) {
      state[key].tag = newTag;
    }
  })
  return state;
}

export function file(state = {}, action) {
  switch (action.type) {
    case fileConstants.DOWNLOAD_IMAGE_REQUEST:
      return Object.assign({}, SetLoadingSateImage(state, action.imageid));
    case fileConstants.DOWNLOAD_IMAGE_SUCCESS:
      return Object.assign({}, PopulateFileImage(state, action.payload));
    case fileConstants.DOWNLOAD_IMAGE_FAILURE:
      return Object.assign({}, SetErrorFile(state, action.imageid, action.error));
    case fileConstants.GET_FILESTATS_REQUEST:
      return Object.assign({}, SetInitialFileStatus(state, action.fileid));
    case fileConstants.GET_FILESTATS_SUCCESS:
      return Object.assign({}, PopulateFileStatus(state, action.filestat));
    case fileConstants.GET_FILESTATS_FAILURE:
      return Object.assign({}, state, SetErrorFile(state, action.fileid, action.error));
    case fileConstants.DELETE_FILE_REQUEST:
      return Object.assign({}, SetFileDeleting(state, action.fileId));
    case fileConstants.DELETE_FILES_REQUEST:
      return Object.assign({}, state);
    case fileConstants.DELETE_FILE_SUCCESS:
      return Object.assign({}, DeleteFile(state, action.fileId));    
    case fileConstants.DELETE_FILES_SUCCESS:
      return Object.assign({}, DeleteFiles(state, action.fileIds));
    case fileConstants.DELETE_FILE_FAILURE:
      return Object.assign({}, state, SetError(state, action.fileId, action.error));
    case fileConstants.DELETE_FILES_FAILURE:
      return Object.assign({}, state, SetErrors(state, action.fileIds, action.error));
    case fileConstants.UPDATE_FILES_SUCCESS:
      return Object.assign({}, UpdateFilesDetail(state, action.fileitem));
    case fileConstants.UPDATE_FILE_REQUEST:
      return Object.assign({}, UpdateFileDetail(state, action.fileitem));
    case fileConstants.UPDATE_FILE_SUCCESS:
      return Object.assign({}, state);
    case fileConstants.UPDATE_FILE_FAILURE:
      return Object.assign({}, state, SetError(state, action.fileid, action.error));
    case fileConstants.RENAME_FOLDER_SUCCESS:
      return Object.assign({}, RenameFolder(state, action.oldTag, action.newTag));

    case fileConstants.DOWNLOAD_REQUEST:
      return Object.assign({}, SetInitialFileStatus(state, action.documentId));
    case fileConstants.DOWNLOAD_SUCCESS:
      return Object.assign({}, UpdateFileDownload(state, action.file));
    case fileConstants.DOWNLOAD_FAILURE:
      return Object.assign({}, state, SetError(state, action.documentId, action.error));

    case boardConstants.BOARD_POPULATEFILELIST_SUCCESS:
      return Object.assign({}, AddBoardFilesList(state, action.items));

    case fileConstants.COMPLETE_DOWNLOAD_FILE_REQUEST:
      return Object.assign({}, UpdateFileDownload(state, action.fileitem));

    case binderConstants.UPDATE_BINDERIMAGE_SUCCESS:
      return Object.assign({}, UpdateBinderImage(state, action.binderItem));

    case binderConstants.REMOVE_BINDER_IMAGE:
      return Object.assign({}, RemoveBinderImage(state, action.binderItem));

    case userConstants.IMPORT_USER_REQUEST:
      return Object.assign({}, state);
    case userConstants.IMPORT_ADD_SUCCESS:
      return Object.assign({}, PopulateReports(state, action.payload));
    case userConstants.IMPORT_USER_FAILURE:
      return Object.assign({}, state);

    case userConstants.UPDATE_USER_INITALPASS_SUCCESS:
      return Object.assign({}, PopulateReport(state, action.pdf, action.id));

    //case boardConstants.AUDIT_POPULATEFILELIST_SUCCESS:
    //  return Object.assign({}, populateAuditLogs(state, action.allItem));

    case binderConstants.RESOLUTION_DOWNLOAD_REQUEST:
      return Object.assign({}, state, DeleteFile(state, action.documentId));
    case binderConstants.RESOLUTION_DOWNLOAD_FAILURE:
      return Object.assign({}, state, SetErrorFile(state, action.documentId, action.error));

//    case customerConstants.SWITCH_CUSTOMER:
//      return {}

    case userConstants.LOGOUT_REQUEST:
      return Object.assign({});
    default:
      return state
  }
}
