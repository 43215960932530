import { binderConstants, userConstants, boardConstants, fileConstants, customerConstants } from '@constants/admin/';
import { BinderItemType } from '@constants/common.constants';

import { BLANK_GUID } from '../../lib/simpletools';

export var ITEM_INITIAL_STATE = {
  id:"",
  loading: true,
  error:"",

  documentId: '',
  key: '',
  position: -1,
  adminPosition: -1,
  indentCount: 0,
  positionString: '',
  adminPositionString: '',
  itemRequiresDecision: '',
  itemPresentedBy: '',
  showItemName: null,
  date: '',
  name: '',
  fileName: '',
  timing: '',
  type: '',
  style: '',
  pageCount: 0,
  expiryDate: null,
  updateDate: '',
  size: 0,
  userItems: [],
  data: null,
  fetch: false,
  verified: null,
  verifying: false,
  isDeleted: false,
  genseckey: false,
};

function SetBinderItem(state, itemId){
  if(state[itemId] === undefined)
    state[itemId] = Object.assign({}, ITEM_INITIAL_STATE);
  state[itemId].id = itemId;
  state[itemId].loading = true;
  state[itemId].error = '';
  return state;
}

function UpdateBinderItem(state, item){
  if(state[item.id] === undefined || state[item.id].type === BinderItemType.Header)
    state[item.id] = Object.assign({}, ITEM_INITIAL_STATE);

  Object.keys(item).map(e => state[item.id][e] = item[e]);
  if(item.adminPosition === undefined)
    state[item.id].adminPosition = item.position

  state[item.id].error = "";
  state[item.id].loading = false;

  return state;
}

function PopulateBinderItem(state, item){
  if(item.hasOwnProperty('items')){
    item.items.forEach(function(object){
      if(state[object.id] === undefined || object.type === BinderItemType.Header)
        state[object.id] = Object.assign({}, ITEM_INITIAL_STATE);

      Object.keys(object).map(e => state[object.id][e] = object[e]);
      if(object.adminPosition === undefined)
        state[object.id].adminPosition = object.position
      state[object.id].id = object.id;
      state[object.id].loading = false;
      state[object.id].error = '';
      state[object.id].isChanged = false;

      //check to see if documentId blank and if so look for gensec key and use those instead
      let documentId = object.documentId
      if(object.documentId === undefined){
        try{
          var f = object.userItems.find(o => o.userId === BLANK_GUID)
          if(f){
            if(f.documentId !== undefined && f.documentId !== ""){
              state[object.id].documentId = f.documentId
              documentId = f.documentId
            }
            if(f.key !== undefined && f.key !== "")
              state[object.id].key = f.key
            if(f.size !== undefined && f.size !== "")
              state[object.id].size = f.size
            state[object.id].genseckey = true
          }
        }catch(e){}
      }else if(state[object.id].genseckey){
        if(object.documentId !== "" && object.key !== undefined && object.key !== "" && object.size !== undefined && object.size !== 0)
          state[object.id].genseckey = false
      }

      if(state[object.id].documentId !== documentId){
        state[object.id].data = null;
      }
    });
  }

  return state;
}

function PopulateAllBinder(state, items){
  for(var key in items){
    for(var x=0; x<items[key].length; x++){
      state = PopulateBinderItem(state, items[key][x]);
    };
  }
  return state;
}

function UpdateDocumentDownload(state, documentitem){
  if(documentitem.id !== ""){
    if(state[documentitem.id] === undefined){
      state[documentitem.id] = Object.assign({}, ITEM_INITIAL_STATE);
      state[documentitem.id].loading = false;
      state[documentitem.id].id = documentitem.id;
      state[documentitem.id].error = '';
    }
    if(documentitem.hasOwnProperty('data'))
      state[documentitem.id].data = documentitem.data;
    state[documentitem.id].fetch = true;
    if(documentitem.hasOwnProperty('documentId'))
      state[documentitem.id].documentId = documentitem.documentId;
  }
  return state;
}

function SetErrorBinderItem(state, itemId, error){
  if(state[itemId] === undefined){
    state[itemId] = Object.assign({}, ITEM_INITIAL_STATE);
    state[itemId].id = itemId;
  }
  state[itemId].loading = false;
  state[itemId].error = error;

  return state;
}

function DeleteBinderItem(state, itemId){
  if(state[itemId] !== undefined){
    delete state[itemId];
  }
  return state;
}

function ClearVoteAnswers(state, itemId){
  if(state[itemId] === undefined){
    state[itemId] = Object.assign({}, ITEM_INITIAL_STATE);
    state[itemId].id = itemId;
  }

  state[itemId].voteAnswer = undefined;

  return state;
}

function AddVoteAnswers(state, itemId, voteAnswer){
  if(state[itemId] === undefined){
    state[itemId] = Object.assign({}, ITEM_INITIAL_STATE);
    state[itemId].id = itemId;
  }

  state[itemId].voteAnswer = voteAnswer;

  return state;
}

function checkingDocumentExists(state, item){
  if(state[item.itemId] !== undefined){
    if(item.userItemId === undefined){
      state[item.itemId].verifying = true;
    }else{
      var f = state[item.itemId].userItems.find(o => o.id === item.userItemId);
      if(f){
        f.verifying = true;
      }
    }
  }

  return state;
}

function resultsDocumentExists(state, item){
  if(state[item.itemId] === undefined){
    state[item.itemId] = Object.assign({}, ITEM_INITIAL_STATE);
    state[item.itemId].id = item.itemId;
  }

  if(item.userItemId === undefined){
    state[item.itemId].verified = item.results;
  }else{
    var f = state[item.itemId].userItems.find(o => o.id === item.userItemId);
    if(f){
      f.verified = item.results;
    }
  }

  return state;
}

function populateAuditLogs(state, doneItem){
  if(doneItem.binderItems !== undefined){
    for(var key in doneItem.binderItems){
      var c = doneItem.binderItems[key];
      state = UpdateBinderItem(state, c);
    }
  }

  return state;
}

export function binderItems(state = {}, action) {
  switch (action.type) {
    case binderConstants.GET_ITEM_REQUEST:
      return Object.assign({}, SetBinderItem(state, action.itemId));
    case binderConstants.GET_ITEM_SUCCESS:
      return Object.assign({}, UpdateBinderItem(state, action.item));
    case binderConstants.GET_ITEM_FAILURE:
      return Object.assign({}, SetErrorBinderItem(state, action.itemId, action.error));
    case binderConstants.POPULATE_BINDERCONTENT_SUCCESS:
      return Object.assign({}, PopulateBinderItem(state, action.item));
    case boardConstants.POPULATE_BINDERS_SUCCESS:
      return Object.assign({}, PopulateAllBinder(state, action.data));
    case binderConstants.POPULATE_TEMPLATECONTENT_SUCCESS:
      return Object.assign({}, PopulateBinderItem(state, action.item));

    case binderConstants.DELETE_ITEM_SUCCESS:
      return Object.assign({}, DeleteBinderItem(state, action.itemId));
    case binderConstants.DELETE_ITEM_FAILURE:
      return Object.assign({}, SetErrorBinderItem(state, action.itemId, action.error));

    case fileConstants.CHECK_DOCUMENT_EXISTS_REQUEST:
      return Object.assign({}, checkingDocumentExists(state, action.items));
    case fileConstants.CHECK_DOCUMENT_EXISTS_SUCCESS:
      return Object.assign({}, resultsDocumentExists(state, action.items));

    case binderConstants.ADD_VOTEANSWERS_REQUEST:
      return Object.assign({}, ClearVoteAnswers(state, action.itemId));
    case binderConstants.ADD_VOTEANSWERS_SUCCESS:
      return Object.assign({}, AddVoteAnswers(state, action.itemId, action.answers));
    case binderConstants.ADD_VOTEANSWERS_FAILURE:
      return Object.assign({}, SetErrorBinderItem(state, action.itemId, action.error));

    case binderConstants.UPDATE_BINDERSTATUS_SUCCESS:
    case binderConstants.NEW_BINDERSTATUS_SUCCESS:
      return Object.assign({}, PopulateBinderItem(state, action.binderItem));
    case binderConstants.UPDATE_TEMPLATECONTENT_SUCCESS:
    case binderConstants.NEW_TEMPLATECONTENT_SUCCESS:
      return Object.assign({}, PopulateBinderItem(state, action.templateItem));

    //case boardConstants.AUDIT_POPULATEFILELIST_SUCCESS:
    //  return Object.assign({}, populateAuditLogs(state, action.allItem));

    case binderConstants.COMPLETE_DOWNLOAD_DOCUMENT_REQUEST:
      return Object.assign({}, UpdateDocumentDownload(state, action.documentitem));

//    case customerConstants.SWITCH_CUSTOMER:
    case userConstants.LOGOUT_REQUEST:
      return Object.assign({});
    default:
      return state
  }
}
