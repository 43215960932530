export const userKeysConstants = {
    ADD_KEY: 'ADD_KEY',
    REMOVE_KEY: 'REMOVE_KEY'
}

export function userKeys(state = {}, action) {
    switch (action.type) {
        case userKeysConstants.ADD_KEY:
            return {
                ...state,
                [action.payload.userId]: action.payload.key
            };
        case userKeysConstants.REMOVE_KEY:
            var newState = { ...state };
            try {
                delete newState[action.payload.userId];
            } catch { }
            return newState;
        default:
            return state;
    }
}