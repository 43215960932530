import { boardConstants, minutesConstants, customerConstants } from '@constants/admin/';
import { BinderItemType, BinderStatus } from '@constants/common.constants';

export var MINUTES_INITIAL_STATE = {
  id:"",
  loading: true,
  populating: false,
  hasPopulated: false,
  error:"",
  boardId: '',
  binderId: '',
  cacheId: '',
  isCached: false,
  saving: false,
  name: '',
  meetingDate: '',
  creationDate: '',
  updateDate: '',
  locationName: '',
  actionIds: null,
  attendees: null,
  invitees: null,
  agendaItems: null,
  actions: null,
  draftIds: [],
  status: BinderStatus.Unpublished,

  exportedToBinderId: "",

  documentId: "",
  key: "",
  size: 0,

  itemCount: 0,
  isDeleted: false,

  lockUserId: '',
  lockExpired: '',
  lockType: '',
};

function loadingMinute(state, minutesId){
  if(state[minutesId] === undefined){
    state[minutesId] = Object.assign({}, MINUTES_INITIAL_STATE);
    state[minutesId].id = minutesId;
  }
  state[minutesId].loading = true;
  return state;
}

function errorMinute(state, minutesId, error){
  if(state[minutesId] === undefined){
    state[minutesId] = Object.assign({}, MINUTES_INITIAL_STATE);
    state[minutesId].id = minutesId;
  }
  state[minutesId].error = error;
  state[minutesId].loading = false;
  return state;
}

function PopulateCachedMinute(state, items){
  items.forEach((o)=>{
    if(state[o.minutesId] !== undefined){
      state[o.minutesId].cacheId = o.minutesId;
      state[o.minutesId].isCached = true;
    }else if(o.minutesId.includes('draft') || o.minutesId.includes('template')){
      if(state[o.minutesId] === undefined){
        state[o.minutesId] = Object.assign({}, MINUTES_INITIAL_STATE);
        state[o.minutesId].id = o.minutesId;
      }
      state[o.minutesId].error = "";
      state[o.minutesId].loading = false;

      state[o.minutesId].cacheId = o.minutesId;
      state[o.minutesId].isCached = true;

      if(o.hasOwnProperty('minuteName'))
        state[o.minutesId].name = o.minuteName;
      if(o.hasOwnProperty('boardId'))
        state[o.minutesId].boardId = o.boardId;
      if(o.hasOwnProperty('binderId'))
        state[o.minutesId].binderId = o.binderId;
      if(o.hasOwnProperty('itemCount'))
        state[o.minutesId].itemCount = o.itemCount;
      if(o.hasOwnProperty('newdate'))
        state[o.minutesId].meetingDate = o.newdate;
      if(o.hasOwnProperty('meetingLoc'))
        state[o.minutesId].locationName = o.meetingLoc;
      if(o.hasOwnProperty('status'))
        state[o.minutesId].status = o.status;
      if(o.hasOwnProperty('exportedToBinderId'))
        state[o.minutesId].exportedToBinderId = o.exportedToBinderId;
    }
  })
  return state;
}

function PopulateMinute(state, o){
  if(state[o.id] === undefined){
    state[o.id] = Object.assign({}, MINUTES_INITIAL_STATE);
    state[o.id].id = o.id;
  }
  state[o.id].error = "";
  state[o.id].loading = false;

  if(o.hasOwnProperty('name'))
    state[o.id].name = o.name;
  if(o.hasOwnProperty('boardId'))
    state[o.id].boardId = o.boardId;
  if(o.hasOwnProperty('binderId'))
    state[o.id].binderId = o.binderId;
  if(o.hasOwnProperty('itemCount'))
    state[o.id].itemCount = o.itemCount;
  if(o.hasOwnProperty('newdate'))
    state[o.id].meetingDate = o.newdate;
  else if(o.hasOwnProperty('meetingDate'))
    state[o.id].meetingDate = o.meetingDate;
  if(o.hasOwnProperty('creationDate'))
    state[o.id].creationDate = o.creationDate;
  if(o.hasOwnProperty('updateDate'))
    state[o.id].updateDate = o.updateDate;
  if(o.hasOwnProperty('locationName'))
    state[o.id].locationName = o.locationName;
  if(o.hasOwnProperty('exportedToBinderId'))
    state[o.id].exportedToBinderId = o.exportedToBinderId;
  if(o.hasOwnProperty('actionIds'))
    state[o.id].actionIds = o.actionIds;
  if(o.hasOwnProperty('attendees'))
    state[o.id].attendees = o.attendees;
  if(o.hasOwnProperty('invitees'))
    state[o.id].invitees = o.invitees;
  if(o.hasOwnProperty('agendaItems'))
    state[o.id].agendaItems = o.agendaItems;
  if(o.hasOwnProperty('actions'))
    state[o.id].actions = o.actions;

  if(o.hasOwnProperty('documentId'))
    state[o.id].documentId = o.documentId;
  if(o.hasOwnProperty('key'))
    state[o.id].key = o.key;
  if(o.hasOwnProperty('size'))
    state[o.id].size = o.size;
  if(o.hasOwnProperty('draftIds'))
    state[o.id].draftIds = o.draftIds;
  if(o.hasOwnProperty('status'))
    state[o.id].status = o.status;
  if(o.hasOwnProperty('draftCount'))
    state[o.id].draftCount = o.draftCount;

  if(o.hasOwnProperty('lockUserId'))
    state[o.id].lockUserId = o.lockUserId;
  if(o.hasOwnProperty('lockExpired'))
    state[o.id].lockExpired = o.lockExpired;
  if(o.hasOwnProperty('lockType'))
    state[o.id].lockType = o.lockType;

  return state;
}

function PopulateMinutes(state, boardId, payload){
  payload.forEach((o)=>{
    state = PopulateMinute(state, o);
  })
  return state;
}

function DeleteMinute(state, minuteId){
  if(state[minuteId] !== undefined){
    if(minuteId.includes('draft') || minuteId.includes('template')){
      delete state[minuteId];
    }else{
      state[minuteId].cacheId = '';
      state[minuteId].isCached = false;
      state[minuteId].isDeleted = true;
    }
  }
  return state;
}

function SetParmMinute(state, minuteId, item){
  if(state[minuteId] !== undefined){
    Object.keys(item).map(e => state[minuteId][e] = item[e]);
  }

  return state;
}

function UpdateLocks(state, items){
  items.forEach((item) => {
    if(item.objectType !== BinderItemType.Minutes) return
    if(state[item.objectId] === undefined){
      state[item.objectId] = Object.assign({}, MINUTES_INITIAL_STATE);
      state[item.objectId].id = item.objectId;
      state[item.objectId].loading = false
    }

    state[item.objectId].lockUserId = item.userId;
    state[item.objectId].lockExpired = item.expiryTime;
    state[item.objectId].lockType = item.detail;
  });

  return state
}

export function minutes(state = {}, action) {
  switch (action.type) {
    case minutesConstants.GET_MINUTES_REQUEST:
      return Object.assign({}, loadingMinute(state, action.minutesId));
    case minutesConstants.GET_MINUTES_SUCCESS:
      return Object.assign({}, PopulateMinute(state, action.minutes));
    case minutesConstants.GET_MINUTES_FAILURE:
      return Object.assign({}, errorMinute(state, action.minutesId, action.error));

    case minutesConstants.GET_MINUTESCACHED_SUCCESS:
      return Object.assign({}, PopulateCachedMinute(state, action.item));
    case boardConstants.GET_BOARD_MINUTES_SUCCESS:
      return Object.assign({}, PopulateMinutes(state, action.boardId, action.payload));

    case minutesConstants.DELETE_MINUTECACHED_SUCCESS:
      return Object.assign({}, DeleteMinute(state, action.id));
    case minutesConstants.DELETE_MINUTECONTENT_SUCCESS:
      return Object.assign({}, DeleteMinute(state, action.minutesId));

    case minutesConstants.SET_MINUTES_REQUEST:
      return Object.assign({}, loadingMinute(state, action.minutesId));
    case minutesConstants.SET_MINUTES_SUCCESS:
      return Object.assign({}, PopulateMinute(state, action.minutes));
    case minutesConstants.SET_MINUTES_FAILURE:
      return Object.assign({}, errorMinute(state, action.minutesId, action.error));

    case minutesConstants.DELETE_MINUTECONTENT_REQUEST:
      return Object.assign({}, SetParmMinute(state, action.minutesId, {loading: true}));

    case minutesConstants.MINUTES_WEBSOCKET_LOCK:
      return Object.assign({}, state, PopulateMinute(state, {id: action.payload.objectId, lockUserId: action.payload.userId, lockExpired: action.payload.expiryTime, lockType: action.payload.detail}));

    case customerConstants.DELETE_LOCKS_SUCCESS:
    case customerConstants.SET_LOCKS_SUCCESS:
      if(action.payload.objectType !== BinderItemType.Minutes) return state
      return Object.assign({}, state, PopulateMinute(state, {id: action.payload.objectId, lockUserId: action.payload.userId, lockType: action.payload.detail}));

    case customerConstants.GET_ALL_LOCKS_SUCCESS:
      return Object.assign({}, state, UpdateLocks(state, action.payload));

//    case customerConstants.SWITCH_CUSTOMER:
//      return {}
    default:
      return state
  }
}
