import { alertConstants, userConstants, webConstants } from '@constants/admin/';

function AddMessage(item){
  if(item.message.hasOwnProperty('code')){
    var newItem = Object.assign({}, item.message);
    newItem.type = 'alert-danger';
    return newItem;
  }
  return {
    type: 'alert-danger',
    message: item.message
  }
}

function AddNoteSuccess(state, message){
  var newItem = Object.assign({type: alertConstants.ALERT_SUCCESS}, message);
  if(state.notification === undefined) state.notification = [];
  state.notification.push(newItem);
  return state;
}

function AddNoteError(state, message){
  var newItem = Object.assign({type: alertConstants.ALERT_DANGER}, message);
  if(state.notification === undefined) state.notification = [];
  state.notification.push(newItem);
  return state;
}

function ClearNote(state, index){
  state.message = undefined;
  state.type = undefined;
  if(state.notification === undefined)
    state.notification = [];
  else
    delete state.notification.splice(index,1);
  return state;
}

function ClearNoteId(state, id){
  if(state.notification === undefined)
    state.notification = [];
  else{
    const p = state.notification.findIndex(o => o.id === id)
    if(p !== -1)
      delete state.notification.splice(p,1);
  }
  return state;
}

function ClearNoteIds(state, ids){
  ids.forEach(id => {
    state = ClearNoteId(state, id)
  })
  return state;
}

function updateProgress(state, progress){
  if(state.progress !== null){
    state.progress.progress = progress
    state.progress.p = progress/state.progress.total * 100
  }
  return state
}

export function alert(state = {notification:[], progress: null}, action) {
  switch (action.type) {
    // case alertConstants.SUCCESS:
    //   return Object.assign({}, state, {
    //     type: 'alert-success',
    //     message: action.message,
    //     item: action.item,
    //   });
    case alertConstants.ERROR:
      return Object.assign({}, state, AddMessage(action));

    case alertConstants.SUCCESS_NOTIFICATION:
      return Object.assign({}, AddNoteSuccess(state, action.message));
    case alertConstants.ERROR_NOTIFICATION:
      return Object.assign({}, AddNoteError(state, action.message));
    case alertConstants.CLEAR_NOTIFICATION:
      return Object.assign({}, ClearNote(state, action.index));
    case alertConstants.CLEAR_BYID_NOTIFICATION:
      return Object.assign({}, ClearNoteId(state, action.id));
    case alertConstants.CLEAR_MULTIPLE_NOTIFICATION:
      return Object.assign({}, ClearNoteIds(state, action.ids));

    case alertConstants.LOADING_INDICATOR_START:
      return Object.assign({}, state, {progress: {p: 0, progress: 0, total: action.total}});
    case alertConstants.LOADING_INDICATOR_PROGRESS:
      return Object.assign({}, updateProgress(state, action.progress));
    case alertConstants.LOADING_INDICATOR_SUCCESS:
      return Object.assign({}, state, {progress: null});

    case alertConstants.CLEAR:
      return Object.assign({}, state, {message: undefined, type: undefined});
    case alertConstants.CLEARALL:
      return Object.assign({}, state, {message: undefined, type: undefined, notification:[]});
    case userConstants.LOGOUT_REQUEST:
      return Object.assign({});
    default:
      return state
  }
}
