import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import * as serviceWorker from '../../serviceWorker';
import { initialiseSmartlook } from '../../lib/smartlookFunctions';

// These libraries are defined in /public/lib and loaded in /index.html
// if(window.initialiseNewRelic && appConfig.newRelic) {
//   window.initialiseNewRelic(appConfig.newRelic);
// }

if(window.googleTagSegment && appConfig.googleTagManager) {
  // window.googleTagSegment(appConfig.googleTagManager);
}

if (appConfig.smartlook) { initialiseSmartlook(); }

// if(window.initialiseSegment && appConfig.segment) {
//   window.initialiseSegment(appConfig.segment);
// }

const LazyLoadedApp = React.lazy(() => import('./app'));

ReactDOM.render(
  <Suspense fallback={<div style={{ width: 'calc(100vw - 16px)', height: 'calc(100vh - 16px)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div className='app-pre-load-spinner'></div></div>}>
    <Provider store={store}>
      <LazyLoadedApp />
    </Provider>
  </Suspense>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
