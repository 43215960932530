import { bulkUploadConstants } from "../../constants/admin";

var INITIAL_STATE = {
    boards: [],
    users: [],
    binders: {}
}

// Users --------------------------------------------------------------
function addUsers(state, users) {
    state.users = state.users.concat(users);
    return state;
}
function updateUser(state, userId, properties = {}) {
    var userToUpdateIndex = state.users.findIndex(u => u.userId == userId);
    if (userToUpdateIndex > -1) {
        state.users[userToUpdateIndex] = { ...state.users[userToUpdateIndex] };
        for (var prop in properties) {
            state.users[userToUpdateIndex][prop] = properties[prop];
        }
    }
    return state;
}
function removeUsers(state, userIds) {
    state.users = state.users.filter(u => !userIds.includes(u.userId));
    return state;
}


// Boards --------------------------------------------------------------
function addBoards(state, boards) {
    state.boards = state.boards.concat(boards);
    return state;
}
function updateBoard(state, boardId, properties = {}) {
    var boardToUpdateIndex = state.boards.findIndex(b => b.boardId == boardId);
    if (boardToUpdateIndex > -1) {
        state.boards[boardToUpdateIndex] = { ...state.boards[boardToUpdateIndex] }
        for (var prop in properties) {
            state.boards[boardToUpdateIndex][prop] = properties[prop];
        }
    }
    return state;
}
function removeBoards(state, boardIds) {
    state.boards = state.boards.filter(b => !boardIds.includes(b.boardId));
    return state;
}


// Binders --------------------------------------------------------------
function addBinders(state, binders) {
    for (var i = 0; i < binders.length; i++) {
        var binder = binders[i];
        if (binder && binder.binderId) {
            state.binders[binder.binderId] = binder
        }
    }
    state.binders = { ...state.binders };
    return state
}
function updateSavedBinderId(state, binderId, newBinderId) {
    var binderToUpdate = state.binders[binderId];
    if (binderToUpdate) {
        state.binders[newBinderId] = { ...binderToUpdate }
        state.binders[newBinderId].id = newBinderId;
        state.binders[newBinderId].binderId = newBinderId;
        delete state.binders[binderId];
    }
    return state;
}
function updateBinder(state, binderId, properties = {}) {
    var binderToUpdate = state.binders[binderId];
    if (binderToUpdate) {
        state.binders[binderId] = { ...binderToUpdate }
        for (var prop in properties) {
            state.binders[binderId][prop] = properties[prop];
        }
    }
    return state;
}
function updateBinderItem(state, binderId, itemIndex, properties = {}) {
    var binderToUpdate = state.binders[binderId];
    if (binderToUpdate) {
        var binderItemToUpdate = binderToUpdate.binderItems[itemIndex];
        if (!binderItemToUpdate) { return state; }
        for (var prop in properties) {
            binderItemToUpdate.binderItem[prop] = properties[prop];
        }
        binderToUpdate.binderItems[itemIndex] = { ...binderItemToUpdate };
    }
    return state;
}

function removeBinderItem(state, binderId, itemIndex) {
    var binderToUpdate = state.binders[binderId];
    if (binderToUpdate) {
        var binderItemToUpdate = binderToUpdate.binderItems[itemIndex];
        if (!binderItemToUpdate) { return state; }
        binderToUpdate.binderItems.splice(itemIndex, 1);
        binderToUpdate.binderItems = [...binderToUpdate.binderItems];
        state.binders[binderId] = { ...binderToUpdate };
    }
    return state;
}

function removeBinders(state, binderIds) {
    for (var id of binderIds) {
        try { delete state.binders[id]; } catch { }
    }
    state.binders = { ...state.binders };
    return state;
}

// ----------------------------------------------------------------------------------------------------------------------------

export function bulkUpload(state = INITIAL_STATE, action) {
    switch (action.type) {
        case bulkUploadConstants.ADD_USERS:
            return addUsers(state, action.payload);
        case bulkUploadConstants.UPDATE_USER:
            return updateUser(state, action.payload.userId, action.payload.properties);
        case bulkUploadConstants.REMOVE_USERS:
            return removeUsers(state, action.payload);
        case bulkUploadConstants.CLEAR_USERS:
            return { ...state, users: [] };


        case bulkUploadConstants.ADD_BOARDS:
            return addBoards(state, action.payload);
        case bulkUploadConstants.UPDATE_BOARD:
            return updateBoard(state, action.payload.boardId, action.payload.properties);
        case bulkUploadConstants.REMOVE_BOARDS:
            return removeBoards(state, action.payload);
        case bulkUploadConstants.CLEAR_BOARDS:
            return { ...state, boards: [] };

        case bulkUploadConstants.ADD_BINDER:
            return addBinders(state, action.payload);
        case bulkUploadConstants.UPDATE_SAVED_BINDER_ID:
            return updateSavedBinderId(state, action.payload.binderId, action.payload.newBinderId);
        case bulkUploadConstants.UPDATE_BINDER:
            return updateBinder(state, action.payload.binderId, action.payload.properties);
        case bulkUploadConstants.UPDATE_BINDER_ITEM:
            return updateBinderItem(state, action.payload.binderId, action.payload.itemIndex, action.payload.properties);
        case bulkUploadConstants.REMOVE_BINDER_ITEM:
            return removeBinderItem(state, action.payload.binderId, action.payload.itemIndex);
        case bulkUploadConstants.REMOVE_BINDERS:
            return removeBinders(state, action.payload);
        case bulkUploadConstants.CLEAR_BINDERS:
            return { ...state, binders: {} };

        case bulkUploadConstants.CLEAR_STATE:
            let newBinders = {};
            for (var k in state.binders) {
                if (state.binders[k].isSaving) {
                    newBinders[k] = state.binders[k];
                }
            }
            return {
                boards: state.boards.filter(b => b.isSaving),
                users: state.users.filter(u => u.isSaving),
                binders: newBinders
            };

        default:
            return state;
    }
}